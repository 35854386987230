import {getLoggers} from 'sopix/log'
import {ChainHubById, ObserverHubById} from 'sopix/utils/observers'

const {log, debug} = getLoggers('router')

export const routers = new ObserverHubById()
export const locationObservers = new ObserverHubById()
export const locationLockers = new ChainHubById()

let latestLocation

const popStateInstalled = false


const updateLatestLocation = () => {
    const previous = latestLocation
    latestLocation = new URL(window.location.href)
    log(()=>`updateLocation: ${previous && previous.pathname}, ${latestLocation.pathname}`)
    return previous
}
updateLatestLocation()

const popStateHandler = e => {
    if (e.state || true) {
        log(()=>`popState: ${latestLocation.pathname}, ${window.location.pathname}`)
        if (locationLockers.get(latestLocation, window.location) === false) {
            window.history.pushState({}, null, latestLocation.href)
            
        } else {
            const previous = updateLatestLocation()
            locationObservers.notify(window.location, previous)
            routers.notify()
        }
    } 
}

export const installHistoryListener = () =>
    !popStateInstalled && window.addEventListener('popstate', popStateHandler)

export const uninstallHistoryListener = () => 
    popStateInstalled && window.removeEventListener('popstate', popStateHandler)


export function replaceUrl(url, {render = true, notify = true, force = false} = {}) {
    return setLocation(new URL(url, window.location.href), {replace: true, render: render, notify: notify, force: force})
}

export function pushUrl(url, {render = true, notify = true, force = false} = {}) {
    return setLocation(new URL(url, window.location.href), {replace: false, render: render, notify: notify, force: force})
}

export function emulateBack(url, {render = true, notify = true, force = false} = {}) {

    const location = new URL(url, window.location.href)

    if (!force && locationLockers.get(latestLocation, url) === false) return

    const currentUrl = window.location.href
    window.history.replaceState({}, null, url)
    window.history.pushState({}, null, currentUrl)
    log(()=>`emulateBakc: back`)
    window.history.back()
    log(()=>`emulateBakc: /back`)

    const previous = updateLatestLocation()

    notify && locationObservers.notify(window.location, previous)

    render && routers.notify()
}

function setLocation(location, {replace = false, render = true, notify = true, force = false} = {}){
    
    if (latestLocation && latestLocation.href === location.href) {
        return
    }

    debug(()=>`setLocation ${location.href}`)
    
    if (!force && locationLockers.get(latestLocation, location) === false) return
    
    if (replace) window.history.replaceState({}, null, location.href)
    else window.history.pushState({}, null, location.href)

    const previous = updateLatestLocation()
    
    notify && locationObservers.notify(window.location, previous)
    
    render && routers.notify()
}

export const renderRouters = () => {
    routers.notify()
}

installHistoryListener()
