import {AnepArea, UniGroup} from "app/utils/dataTypes";
import {GroupedBox} from "sopix/boxGrid/groupedBox";
import {Vector2} from "sopix/cartesian/cartesian";
import {action, computed, makeObservable, observable} from "mobx";

function getTouchingSide(a: Vector2, b: Vector2): 'top' | 'right' | 'bottom' | 'left' | undefined {
    if (a.isUndefined || b.isUndefined) {
        return undefined
    }
    const offset = a.offset(b)
    if (offset.equal(new Vector2(0, -1))) {
        return 'top'
    } else if (offset.equal(new Vector2(1, 0))) {
        return 'right'
    } else if (offset.equal(new Vector2(0, 1))) {
        return 'bottom'
    } else if (offset.equal(new Vector2(-1, 0))) {
        return 'left'
    } else {
        return undefined
    }
}

export class CellBaseState extends GroupedBox {

    element: HTMLElement

    focused = false

    onMouseEnter(event) {
        this.focused = true
    }

    onMouseLeave(event) {
        this.focused = false
    }

    constructor(public x?: number, public y?: number) {
        super(x, y)
        makeObservable(this, {
            focused: observable,
            onMouseEnter: action.bound,
            onMouseLeave: action.bound,
        })
    }

    registerElement(element) {
        this.element = element
        element.addEventListener('mouseenter', this.onMouseEnter)
        element.addEventListener('mouseleave', this.onMouseLeave)
    }

    unregisterElement(element) {
        this.element.removeEventListener('mouseleave', this.onMouseLeave)
        this.element.removeEventListener('mouseenter', this.onMouseEnter)
        this.element = undefined
    }
}

export class NullCellState extends CellBaseState {
    constructor(public x: number, public y: number) {
        super(x, y)
    }
}

export class TitleCellState extends CellBaseState {

    leafCells: Map<string, LeafCellState>

    get groups(): Map<any, UniGroup> {
        let groups = []
        for (const leafCell of this.leafCells.values()) {
            groups = [...groups, ...leafCell.groups.entries()]
        }
        return new Map(groups)
    }

    get leafFocused() {
        let focus = false
        for (const cell of this.leafCells.values()) {
            if (cell.focused) {
                focus = true
                break
            }
        }
        return focus
    }

    constructor(x: number, y: number, public areaRef: string, public area: AnepArea, public areaCell?: AreaCellState) {
        super(x, y)
        makeObservable(this, {
            leafFocused: computed,
        })
    }

    get groupId(): string | undefined {
        return this.areaRef
    }

    get leafAreaSide() {
        if (!this.areaCell) {
            return undefined
        }
        return getTouchingSide(this.pos, this.areaCell.pos)
    }

    get name() {
        return this.area.name
    }
}


export class LeafCellState extends CellBaseState {
    constructor(x: number, y: number, public areaRef: string, public area: AnepArea, public groups: Map<any, UniGroup>, public titleCell: TitleCellState) {
        super(x, y)
    }

    get groupId(): string | undefined {
        return this.areaRef
    }

    get ref() {
        return this.areaRef
    }

    get name() {
        return this.area.name
    }

    get titleSide() {
        return getTouchingSide(this.pos, this.titleCell.pos)
    }
}

export class AreaCellState extends LeafCellState {

}

export class SubareaCellState extends LeafCellState {
    constructor(x: number, y: number, public areaRef: string, public area: AnepArea,
                public subareaRef: string, public subarea: AnepArea, public groups: Map<any, UniGroup>, public titleCell: TitleCellState) {
        super(x, y, areaRef, area, groups, titleCell)
    }

    get ref() {
        return this.subareaRef
    }

    get name() {
        return this.subarea.name
    }
}