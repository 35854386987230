import {isArray, isFunction} from 'lodash-es'

export const LogLevel = Object.freeze({
    DEBUG: 0,
    INFO: 1,
    LOG: 2,
    NONE: 100,
})
    
const DEBUG = LogLevel.DEBUG
const INFO = LogLevel.INFO
const LOG = LogLevel.LOG

const LOG_TAGS = {
    ApiEndPoint: LOG,
    SopRouter: DEBUG,
}


function isLogEnabled(level, unit, commonTags, tags = []){
    const tags_ = isArray(tags) ? tags : [tags]
    
    let enabled = false
    for (let tag of [unit, ...commonTags, ...tags_]) {
        const required_level = LOG_TAGS[tag]
        if (required_level !== undefined && level >= required_level) {
            enabled = true
            break
        }
    }
    return enabled
}


export function getLog(unit, commonTags = [], level:number = LogLevel.LOG){
    if (process.env.NODE_ENV !== 'development') return () => {}

    return (message, tags = []) => {
        
        const log = isLogEnabled(level, unit, commonTags, tags)
            
        if (log){
            const message_ = isFunction(message) ? message() : message
            console.log(`[${unit}]`, message_)
        }
    }
}

export function getLoggers(unit, commonTags = []){
    if (process.env.NODE_ENV !== 'development') return {
        log: () => {},
        info: () => {},
        debug: () => {},
        logEnabled: () => false,
    }
    
    const log = getLog(unit, commonTags, LogLevel.LOG)
    const info = getLog(unit, commonTags, LogLevel.INFO)
    const debug = getLog(unit, commonTags, LogLevel.DEBUG)

    const commonTags_ = isArray(commonTags) ? commonTags : [commonTags]

    const logEnabled = (level = LogLevel.LOG, tags) =>
        isLogEnabled(level, unit, commonTags_, tags)
    
    return {log, info, debug, logEnabled}
}
