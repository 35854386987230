import {Boundaries} from "sopix/boxGrid/boxGridDefs";

type Point = [number, number]

const touchingBoxRotations: Point[] = [[1, 0], [0, 1], [-1, 0], [0, -1]]

const complex_multiply = (a: Point, b: Point): Point => [a[0] * b[0] - a[1] * b[1], a[0] * b[1] + a[1] * b[0]]
const complex_sum = (a: Point, b: Point): Point => [a[0] + b[0], a[1] + b[1]]

/**
 * Genera svg path para asociar a un elemento. Usa unidades entre 0 y 1 para aplicar con objectBoundingBox en clip-path
 *
 * @param margin margen del path entre 0 y 0.5
 * @param round radio de las esquinas entre 0 y 0.5
 * @param boundaries 8 booleanos indicando si hay fronteras de grupo alrededor en cada una de las 8 direcciones del
 * espacio. Se empieza por arriba, luego arriba-derecha y se va rotando en el sentido de las agujas del reloj.
 */
export function calcTouchingBoxesPath(margin: number, round: number, boundaries: Boundaries) {

    const parts = []

    const b = boundaries
    const rel = (n: number) => boundaries[(8 + n) % 8]

    for (const [rot_no, r] of touchingBoxRotations.entries()) {

        const rot = (point: Point) => {
            const pointFromCenter: Point = complex_sum(point, [-0.5, -0.5])
            const pointRotated = complex_multiply(pointFromCenter, touchingBoxRotations[rot_no])
            const result = complex_sum(pointRotated, [0.5, 0.5])
            return [Math.round(100 * result[0]) / 100, Math.round(100 * result[1]) / 100]
        }

        // START POINT
        let s: Point

        // Top !Left
        if (b[2 * rot_no] && !rel(2 * rot_no - 2)) {
            s = [0, margin]

            // !Top Left
        } else if (!b[2 * rot_no] && rel(2 * rot_no - 2)) {
            s = [margin, 0]

            // Top Left
        } else if (b[2 * rot_no] && rel(2 * rot_no - 2)) {
            s = [margin + round, margin]

            // !Top !Left TopLeft
        } else if (!b[2 * rot_no] && !rel(2 * rot_no - 2) && rel(2 * rot_no - 1)) {
            s = [margin, 0]

            // !Top !Left !TopLeft
        } else {
            s = [0, 0]
        }

        const s_rot = rot(s)
        parts.push(`${rot_no === 0 ? 'M' : 'L'}${s_rot[0]} ${s_rot[1]}`)

        // START POINT
        let t: Point
        let q: Point
        let t2: Point

        // !Top Right
        if (!b[2 * rot_no] && rel(2 * rot_no + 2)) {
            t = [1 - margin, 0]

            // Top !Right
        } else if (b[2 * rot_no] && !rel(2 * rot_no + 2)) {
            t = [1, margin]

            // Top Right
        } else if (b[2 * rot_no] && rel(2 * rot_no + 2)) {
            t = [1 - margin - round, margin]
            q = [1 - margin, margin]
            t2 = [1 - margin, margin + round]

            // !Top !Right TopRight
        } else if (!b[2 * rot_no] && !rel(2 * rot_no + 2) && rel(2 * rot_no + 1)) {
            t = [1 - margin, 0]
            q = [1 - margin, margin]
            t2 = [1, margin]

            // !Top !Right !TopLeft
        } else {
            t = [0, 0]
        }

        const t_rot = rot(t)
        parts.push(`L${t_rot[0]} ${t_rot[1]}`)

        if (t2) {
            const q_rot = rot(q)
            const t2_rot = rot(t2)
            parts.push(`Q${q_rot[0]} ${q_rot[1]} ${t2_rot[0]} ${t2_rot[1]}`)
        }
    }

    return parts.join(' ')

}
