import {GroupedBox} from "./groupedBox";
import {times} from "lodash-es";
import {Boundaries, BoxAround, boxAround} from "sopix/boxGrid/boxGridDefs";

export class BoxGrid<T extends GroupedBox> {
    cells: GroupedBox[][]

    constructor(public xSize: number, public ySize: number) {
        this.cells = times(ySize, () => [])
    }

    getCellBoundaries(x: number, y: number): Boundaries {
        const groupId = this.cells[y][x].groupId

        const boundaries = boxAround.map(([dX, dY]) => {
            const cell = {x: x + dX, y: y + dY}
            if (cell.x < 0 || cell.y < 0 || cell.x >= this.xSize || cell.y >= this.ySize) {
                return true
            }
            const sideCell = this.cells[cell.y][cell.x]
            return sideCell.groupId !== groupId
        })
        return boundaries as unknown as Boundaries
    }
}
