import {isArray} from "lodash-es"
import {UniGroupRaw} from "app/utils/dataTypes";

/**
 * A partir de una lista de objetos devuelve un Map. El key es el campo identificado por keyField. Si no se
 * aporta un keyField se usará un key numérico autoincrementado desde 1.
 *
 * @param array un array de objetos
 * @param keyField el field key que se usará como key del map
 */
export function arrayToMap(array: {}[], keyField?: string) {
    const map = new Map()
    let id = 0
    for(const rec of array){
        const key = keyField ? rec[keyField] : ++id
        if (map.has(key)) {
            throw Error(`Repeated CSV "${keyField}" field: "${key}"`)
        }
        map.set(key, rec)
    }
    return map
}

type KeyField = ((T, string) => string) | string

type IndexEntry<T> = T | Map<any, T>
type IndexBlock<T> = Map<any, IndexEntry<T>>
type IndexMap<T> = Map<any, IndexBlock<T>>

export type IndexMapLevel1<T> = Map<any, Map<any, T>>
export type IndexMapLevel2<T> = Map<any, IndexMapLevel1<T>>


export function indexMapEntry<T extends {}>(
    indexMap: IndexMapLevel1<T> | IndexMapLevel2<T>,
    groupKeys: KeyField | KeyField[],
    key,
    obj
) {

    const groupKeyArray: KeyField[] = Array.isArray(groupKeys) ? groupKeys : [groupKeys]

    let currentIndex = indexMap
    let pendingToTip = groupKeyArray.length
    for (const groupKey of groupKeyArray) {
        pendingToTip--

        // Read or create key map
        let blockEntries
        if (currentIndex.has(groupKey)) {
            blockEntries = currentIndex.get(groupKey)
        } else {
            blockEntries = new Map()
            currentIndex.set(groupKey, blockEntries)
        }

        if (!pendingToTip) {
            // Tip: assign object
            blockEntries.set(key, obj)
        } else {
            // Next index
            currentIndex = blockEntries
        }
    }
}


/**
 * Si se incluye un único keyField, devuelve un map de maps. En el map externo en el que la clave es el keyField.
 * En el map interno se incluyen todos los registros con el mismo keyfield. El key del map interno es la clave del
 * mapa original completo.
 *
 * Si se incluyen 2 keyfields, devuelve un map de maps de maps. El último, la punta, contiene los valores, los otros
 * mapas son agrupaciones.
 *
 * Si se incluyen más de 2 keyfields, el funcionamiento es similar pero con más niveles de agrupación.
 *
 * A parte de un incluir strings en los keyfields se pueden incluir funciones que computen el key dinámicamente.
 *
 * @param map mapa con datos origen
 * @param keyFields valor o array de valores para grupos y subgrupos
 */
export function indexMap<T extends {}>(
    map: Map<any, T>,
    keyFields: KeyField | KeyField[]
): IndexMap<T>{

    const mapIndex = new Map<any, IndexBlock<T>>()
    const keyFieldArray: KeyField[] = Array.isArray(keyFields) ? keyFields : [keyFields]

    for (const [key, obj] of map.entries()){
        const groupKeys = []
        for (const keyField of keyFieldArray) {
            groupKeys.push(keyField instanceof Function ? keyField(obj, key) : obj[keyField])
        }
        indexMapEntry(mapIndex, groupKeys, key, obj)
    }
    return mapIndex
}


// export function addToIndexMap<T>(map: IndexMapLevel1<T>, groupKeys: KeyField | KeyField[], entryKey, entry) {
//     const groupKeyArray: KeyField[] = Array.isArray(groupKeys) ? groupKeys : [groupKeys]
//
//     let group = map.get(groupKey)
//     if (!group) {
//         group = new Map<any, T>()
//         map.set(groupKey, group)
//     }
//     if (!group.has(entryKey)) {
//         group.set(entryKey, entry)
//     }
// }