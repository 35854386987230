import './Filter.scss'
import {Chip, Divider, IconButton, InputAdornment, TextField, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {filterState} from "app/filter/filterState";
import {FilterMatches} from "app/filter/FilterMatches";
import React from "react";
import {DropdownFilter} from "app/filter/DropdownFilter";
import {allData} from "app/filter/allData";
import {AnepArea} from "app/utils/dataTypes";
import {filterMap} from "sopix/collection/filterMap";
import {action} from "mobx";
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from "@mui/icons-material/Close";

function renderDropdownEntry(ref, name, className, chipClass) {
    return (
        <div className={className}>
            <Chip className={chipClass} size="small" label={ref}/> <span>{name}</span>
        </div>
    )
}

function getAreaDropDownEntries() {
    const entries = new Map()

    for (const area of filterMap<AnepArea>(allData.areas, area => !area.parent).values()) {
        entries.set(area.ref, renderDropdownEntry(area.ref, area.name, 'area', 'area-chip'))

        for (const subarea of area.subareas.values()) {
            entries.set(subarea.ref, renderDropdownEntry(subarea.ref, subarea.name, 'subarea', 'subarea-chip'))
        }
    }

    return entries
}

function getUniDropDownEntries() {
    return new Map(Array.from(allData.unis?.values()).map(uni =>
        [uni.ref, renderDropdownEntry(uni.ref, uni.name, 'uni', 'uni-chip')]))
}


export const FilterChips = observer(() => {
    const entries = []

    for (const uni of filterState.universityFilter.values()) {
        entries.push(
            <Tooltip key={uni.ref} title={uni.name}>
                <Chip className="uni-chip" size="small" key={`uni-${uni.ref}`} label={uni.ref} color="primary"
                      onDelete={() => filterState.unselectUniversity(uni.ref)}/>
            </Tooltip>
        )
    }

    function addAreaChip(area) {
        entries.push(
            <Tooltip key={area.ref} title={area.name}>
                <Chip className={area.parent ? 'subarea-chip' : 'area-chip'} size="small" key={`area-${area.ref}`}
                      color="primary" label={area.ref} onDelete={() => filterState.unselectArea(area.ref)}/>
            </Tooltip>
        )
    }

    for (const area of filterMap<AnepArea>(allData.areas, a => !a.parent).values()) {
        if (filterState.areaFilter.has(area.ref)) {
            addAreaChip(area)
        }

        for (const subarea of area.subareas.values()) {
            if (filterState.areaFilter.has(subarea.ref)) {
                addAreaChip(subarea)
            }
        }
    }

    return !entries.length ? null : (
        <div className="filter-chips">
            {entries}
        </div>
    )
})

export const Filter = observer(() => {

    return !allData.initialized ? null : (
        <div className="filter">
            <div className="filter-menus">
                <Typography className="title" variant="overline">Filters</Typography>
                <DropdownFilter id="area" title="Area" onSelect={filterState.toggleArea}
                                isSelectedFunc={filterState.isAreaSelected}>
                    {getAreaDropDownEntries()}
                </DropdownFilter>
                <DropdownFilter id="uni" title="University" onSelect={filterState.toggleUniversity}
                                isSelectedFunc={filterState.isUniSelected}>
                    {getUniDropDownEntries()}
                </DropdownFilter>
            </div>
            <FilterChips/>
            <Divider/>
            <div className="search">
                <TextField id="filter" variant="standard" value={filterState.filterText} onChange={action(
                    event => filterState.filterText = event.target.value)}
                           InputProps={{
                               placeholder: 'Search here',
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               ),
                               ...(!filterState.filterText ? {} : {
                                   endAdornment: (
                                       <IconButton size="small" onClick={action(() => {
                                           filterState.filterText = ''
                                       })}>
                                           <CloseIcon fontSize="small"/>
                                       </IconButton>
                                   ),
                               }),
                           }}
                />
            </div>
            <FilterMatches/>
        </div>
    )
})