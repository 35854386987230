import React, {ReactNode, useRef} from "react";
import {Filter} from "app/filter/Filter";
import {Header} from "app/frame/Header";
import './FrameWithFilter.scss'
import {Footer} from "app/frame/Footer";
import {useSizeObserver} from "sopix/hooks/useSizeObserver";
import {appState} from "app/app/appState";
import clsx from "clsx";
import {urlState} from "app/app/urlState";

interface Props{
    children: ReactNode,
}

export const FrameWithFilter = ({children}: Props) => {
    const ref = useRef()

    useSizeObserver(ref, appState.onElementResize)

    return (
        <div ref={ref}
             className={clsx(
                 'frame-with-filter',
                 !appState.screenSize ? null : `size-${appState.screenSize}`,
                 !urlState.view ? null : `view-${urlState.view}`,
             )}>
            <Header />
            <div className="middle">
                <div className="left">
                    <Filter />
                </div>
                <div className="right">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
}
