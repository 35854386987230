export class GridSize {
    xCount: number
    yCount: number

    constructor(readonly divWidth: number, readonly divHeight: number, readonly cellCount: number) {
        if (cellCount) {
            this.calcGridSize()
        }
    }

    private calcGridSize(){
        const divWidth = this.divWidth
        const divHeight = this.divHeight
        const count = this.cellCount

        function calc(isY: boolean, roundFunc){
            const div1 = isY? divHeight: divWidth
            const div2 = isY? divWidth: divHeight
            const aspectRatio = div1 / div2
            const dim1 = roundFunc(Math.sqrt(count * aspectRatio))
            const dim2 = 1 + Math.floor((count - 1) / dim1)
            const cellCount = dim1 * dim2

            return isY? {
                x: dim2,
                y: dim1,
                xSide: div2 / dim2,
                ySide: div1 / dim1,
                cellCount,
            }: {
                x: dim1,
                y: dim2,
                xSide: div1 / dim1,
                ySide: div2 / dim2,
                cellCount,
            }
        }

        const options:{x: number, y: number, xSide: number, ySide: number, cellCount: number}[] = [
            calc(false, Math.ceil),
            // calc(false, Math.floor),
            calc(true, Math.ceil),
            // calc(true, Math.floor),
        ]

        const optionsFilter = options.filter(o => o.cellCount >= count)

        const optionsSort =
            optionsFilter.sort(
                (o1, o2) => o1.cellCount - o2.cellCount)

        const winer = optionsSort[0]

        this.xCount = winer.x
        this.yCount = winer.y
    }

    hasSameSize(gridState: GridSize) {
        return gridState.xCount === this.xCount && gridState.yCount === this.yCount;
    }
}