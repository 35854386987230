import {makeObservable, observable} from "mobx";
import {routers} from "sopix/router/router";
import {allData} from "app/filter/allData";
import {boundMethod} from "autobind-decorator";
import {size} from "lodash-es";

class AppState{
    @observable
    initialized: boolean = false

    @observable
    screenSize: 'xs' | 's' | 'l' | 'xl' | undefined

    constructor() {
        makeObservable(this)
    }

    async init(){
        if (this.initialized) {return}

        await allData.init()
    }

    @boundMethod
    onElementResize(element: HTMLDivElement) {
        if (!element) {
            this.screenSize = undefined
            return
        }

        const sizes = {
            xs: 350,
            s: 800,
            l: 1024,
            xl: 1480,
        }

        let widthKey = undefined
        for (const [key, width] of Object.entries(sizes)) {
            if (element.clientWidth >= width) {
                widthKey = key
            }
        }
        this.screenSize = widthKey
    }
}

export const appState = new AppState()