/** @jsxImportSource @emotion/react */
import './TextView.scss'
import {observer} from "mobx-react-lite";
import React, {useRef, useState} from "react";
import {filterState} from "app/filter/filterState";
import {allData} from "app/filter/allData";
import {NO_AREA} from "app/utils/dataTypes";
import {Group} from "app/textView/Group";
import {css} from "@emotion/react";
import {useGoTopOnFilter} from "app/filter/filterHooks";
import {getHighlightInfo} from "app/filter/filterUtils";
import {Button, Divider} from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {emulateBack} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";
import {FilterSummary} from "app/filter/FilterSummary";
import {Highlighted} from "app/filter/Highlighted";

const LAZY_COUNT = 2

export const TextView = observer(() => {

    const areaTree = filterState?.data?.groupsByCell || new Map()

    const [visibleGroupCount, setVisibleGroupCount] = useState(2 * LAZY_COUNT)

    let groupCount = 0

    const triggerGroupRef = useRef<HTMLDivElement>()

    const textViewRef = useGoTopOnFilter()

    const scrolled = event => {
        if (!triggerGroupRef.current || !textViewRef.current) {
            return
        }
        if (triggerGroupRef.current.scrollTop + triggerGroupRef.current.clientHeight >= textViewRef.current.clientTop) {
            setVisibleGroupCount(visibleGroupCount + LAZY_COUNT)
        }
    }

    const includedGroups = new Set()

    // Main areas
    const mainAreas = []
    let stopRender = false
    for (const [mainAreaRef, areaMap] of areaTree.entries()) {
        const mainArea = allData.areas.get(mainAreaRef) || NO_AREA

        // Leaf areas
        const leafAreas = []
        for (const [areaRef, groupMap] of areaMap.entries()) {

            // Highlighted
            const [highlighted, dimmed, highlightedGroups] = getHighlightInfo([...groupMap.keys()])

            // Groups
            const groups = []
            for (const group of groupMap.values()) {
                if (highlightedGroups && !highlightedGroups.has(group.ref)) {
                    continue
                }

                const isTriggerGroup = groupCount === visibleGroupCount - LAZY_COUNT

                const repeated = includedGroups.has(group.ref)
                if (!repeated) {
                    includedGroups.add(group.ref)
                }

                groups.push(<Group key={group.ref} ref={isTriggerGroup ? triggerGroupRef : undefined} group={group}
                                   repeated={repeated}/>)

                groupCount++

                if (groupCount >= visibleGroupCount) {
                    stopRender = true
                    break
                }
            }

            if (!groups.length) {
                continue
            }

            // RENDER leaf area
            const leafArea = allData.areas.get(areaRef)
            if (!leafArea || !leafArea.parent) {
                leafAreas.push(
                    <div key={leafArea?.ref || '___'} className="main-area-groups">
                        {groups}
                    </div>
                )
            } else {
                leafAreas.push(
                    <div key={leafArea.ref} className="subarea">
                        <h3 css={css(css`
                          background: ${leafArea.backgroundColor};
                          color: ${leafArea.color};
                        `)}>
                            <span className="anep-ref">{leafArea.ref}</span> {leafArea.name}
                        </h3>
                        {groups}
                    </div>
                )
            }

            if (stopRender) {
                break
            }
        }

        if (!leafAreas.length) {
            continue
        }

        // RENDER main area
        mainAreas.push(
            <div key={mainArea.ref} className="main-area">
                <h2 css={css(css`
                  background: url(/img/anep/${mainArea.ref}.webp) center center no-repeat;
                `)}>
                    <span className="text">
                        <span className="anep-ref">{mainArea.ref}</span>
                        <span className="title">{mainArea.name}</span>
                    </span>
                </h2>
                {leafAreas}
            </div>
        )

        if (stopRender) {
            break
        }
    }

    const backToSearchClick = event => {
        emulateBack(updateUrlQuery({
            view: 'chart',
        }))
    }

    return (
        <div className="text-view-container">
            <section ref={textViewRef} className="text-view" onScroll={scrolled}>
                <div className="buttons">
                    <Button startIcon={<NavigateBeforeIcon/>} onClick={backToSearchClick}>Back to
                        search</Button>
                </div>
                <div className="small-screen">
                    <Divider/>
                    {filterState.isHighlighted ?
                        <Highlighted/> :
                        <FilterSummary/>
                    }
                </div>
                {mainAreas}
            </section>
        </div>
    )
})
