import {AreaMap} from "app/utils/dataTypes";

export const getLeafAreas = (areas: AreaMap, subareas: AreaMap): AreaMap => {
    const resultAreas = new Map(areas.entries())
    for (const subarea of subareas.values()) {
        const parentRef = subarea.parent.ref
        if (resultAreas.has(parentRef)) {
            resultAreas.delete(parentRef)
        }
    }
    return new Map([...resultAreas.entries(), ...subareas.entries()])
}