import {ExternalIcon} from "app/icons/ExternalIcon";

interface Props{
    webs: string[]
}
export function WebList({webs}: Props){

    if (!webs) {
        return null
    }

    const webElements = []

    let i = 0
    for (const web of webs) {
        const suffix = ++i === 1 ? '' : ` ${i}`
        webElements.push(
            <a key={i} href={web} target="_blank">{`Website${suffix}`}<ExternalIcon/></a>
        )
    }

    return <>
        {webElements}
    </>
}