import './DropdownFilter.scss'
import {observer} from "mobx-react-lite";
import {Button, Fade, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {ReactNode, useRef} from "react";
import {Check} from "@mui/icons-material";
import {urlState} from "app/app/urlState";
import {emulateBack, pushUrl, replaceUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";
import CloseIcon from "@mui/icons-material/Close";

interface Props{
    className?: string
    id: string
    title: string
    onSelect: (any) => void
    isSelectedFunc?: (any) => boolean
    children: Map<any, ReactNode>
}


export const DropdownFilter = observer(({className, id, title, onSelect, isSelectedFunc, children}: Props) => {

    const open = urlState.dropdown === id

    const buttonRef = useRef<HTMLButtonElement>()

    const handleClick = event => {
        replaceUrl(updateUrlQuery({
            dropdown: id,
        }))
    }
    const handleClose = event => {
        emulateBack(updateUrlQuery({
            dropdown: '',
        }))
    }

    function renderEntries(){
        const entries = []
        for (const [ref, node] of children) {
            const isSelected = isSelectedFunc && isSelectedFunc(ref)
            const itemContent = !isSelected ? <ListItemText inset>{node}</ListItemText> : (
                <>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    {node}
                </>
            )
            entries.push(<MenuItem key={ref} className="entry" onClick={event =>
                onSelect(ref)}>{itemContent}</MenuItem>)
        }
        return entries
    }

    return (
      <div className={className} >
          <Button
              ref={buttonRef}
              id={id}
              aria-controls={open ? `${id}-menu` : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              size="small"
              onClick={handleClick}
          >
              {title}
          </Button>
          {!buttonRef.current ? null :
              <Menu
                  id={`${id}-menu`}
                  className="dropdown-filter"
                  aria-labelledby={`${id}-menu`}
                  anchorEl={buttonRef.current}
                  open={open}
                  onClose={e => handleClose(e)}
                  TransitionComponent={Fade}
              >
                  <div className="close">
                      <IconButton className="close-button" aria-label="close" size="small" onClick={handleClose}>
                          <CloseIcon fontSize="small"/>
                      </IconButton>
                  </div>
                  {renderEntries()}
              </Menu>
          }
      </div>
)
})
