import React from "react";
import {NullCellState} from "app/chartView/cell/cellState";

interface Props{
    cell: NullCellState
}
export const NullCell = ({cell}: Props) => {
    return <div className="cell null-cell"></div>
}

