import {Vector2} from "sopix/cartesian/cartesian";

export class GroupedBox {

    pos: Vector2

    constructor(public x?: number, public y?: number) {
        this.pos = new Vector2(x, y)
    }

    get groupId(): string | undefined {
        return undefined
    }
}

