import {FilteredData} from "app/filter/filteredData";
import {
    AreaCellState,
    TitleCellState,
    CellBaseState, LeafCellState,
    NullCellState,
    SubareaCellState
} from "app/chartView/cell/cellState";
import {GridSize} from "app/chartView/grid/gridSize";
import {meander} from "app/utils/gridWorms";
import {allData} from "app/filter/allData";
import {range, times} from "lodash-es"
import {BoxGrid} from "sopix/boxGrid/boxGrid";
import {calcTouchingBoxesPath} from "sopix/boxGrid/touchingBoxesPath";
import {NO_AREA} from "app/utils/dataTypes";

export class GridData {

    grid: BoxGrid<CellBaseState>

    get cells() {
        return this.grid.cells
    }

    constructor(readonly gridSize: GridSize, readonly filteredData: FilteredData) {
        this.fillCells()
        this.calcClipPaths()
    }

    clipPaths: { [key: string]: string }

    private fillCells() {
        const worm = meander(this.gridSize.xCount, this.gridSize.yCount)

        const readWorm = () => {
            const {value, done} = worm.next()
            if (done) {
                throw Error('Worm ended prematurely')
            }
            return value
        }

        let debugSubareas = []
        const grid = new BoxGrid<CellBaseState>(this.gridSize.xCount, this.gridSize.yCount)
        const cells = grid.cells
        for (let [areaRef, groupsBySubCell] of this.filteredData.groupsByCell.entries()) {
            const area = allData.areas.get(areaRef) || NO_AREA

            // Grid boxes
            const boxesRaw = []
            for (let i = groupsBySubCell.size + 1; i > 0; i--) {
                boxesRaw.push(readWorm())
            }

            // Short
            const boxes = boxesRaw[0].y !== boxesRaw[1].y ?
                boxesRaw :
                boxesRaw.sort((a, b) => 1000 * (a.y - b.y) + a.x - b.x)

            // Area cell
            let box = boxes.shift()
            const titleCell = new TitleCellState(box.x, box.y, areaRef, area)
            cells[box.y][box.x] = titleCell

            // Subareas
            const leafCells = new Map<string, LeafCellState>()
            for (let [anepRef, anepGroups] of groupsBySubCell.entries()) {
                const anep = allData.areas.get(anepRef) || NO_AREA

                // Anep cell
                box = boxes.shift()
                let cell
                if (anep.parent) {
                    cell = new SubareaCellState(box.x, box.y, areaRef, area, anepRef, anep, anepGroups, titleCell)
                } else {
                    cell = new AreaCellState(box.x, box.y, areaRef, area, anepGroups, titleCell)
                    titleCell.areaCell = cell
                }
                leafCells.set(anepRef, cell)

                cells[box.y][box.x] = cell
            }
            titleCell.leafCells = leafCells
        }

        // Rest
        for(let {x,y} of worm) {
            cells[y][x] = new NullCellState(x,y)
        }

        this.grid = grid
    }


    private calcClipPaths() {
        const clipPaths = {}
        for (const y of range(this.gridSize.yCount)) {
            for (const x of range(this.gridSize.xCount)) {
                const boundaries = this.grid.getCellBoundaries(x, y)
                const ref = boundaries.map(b => b ? '1' : '0').join('')
                clipPaths[ref] = calcTouchingBoxesPath(.03, .02, boundaries)
            }
        }
        this.clipPaths = clipPaths
    }

}