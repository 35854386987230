import {action, autorun, computed, makeObservable, observable, runInAction} from "mobx";
import {FilteredData} from "app/filter/filteredData";
import {allData} from "app/filter/allData";
import {debounced} from "app/utils/debouncing";
import {filterGroups, getMapFromUrl} from "app/filter/filterUtils";
import {FilterMatchSectionName} from "app/filter/filterDefs";
import {AnepArea, Uni} from "app/utils/dataTypes";
import {boundMethod} from "autobind-decorator";
import {urlState} from "app/app/urlState";
import {pushUrl, replaceUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";

class FilterState{

    @observable.ref
    data?: FilteredData

    @observable
    selectedSection?: FilterMatchSectionName

    @observable
    selectedIndex?: number

    @computed
    get universityFilter() {
        return getMapFromUrl<Uni>('unis', 'unis')
    }

    set universityFilter(newUni) {
        const dropdown = urlState.dropdown
        replaceUrl(updateUrlQuery({
            unis: [...newUni.keys()].join('.'),
            dropdown: undefined,
        }))

        if (dropdown) {
            pushUrl(updateUrlQuery({
                dropdown: dropdown,
            }))
        }
    }

    @computed
    get areaFilter() {
        return getMapFromUrl<AnepArea>('areas', 'areas')
    }

    set areaFilter(newUni) {
        const dropdown = urlState.dropdown
        replaceUrl(updateUrlQuery({
            'areas': [...newUni.keys()].join('.'),
            dropdown: undefined,
        }))
        if (dropdown) {
            pushUrl(updateUrlQuery({
                dropdown: dropdown,
            }))
        }
    }


    @computed
    get filterText() {
        return urlState.filter || ''
    }

    set filterText(newText) {
        replaceUrl(updateUrlQuery({
            filter: newText,
        }))
    }

    @observable
    count = 0

    @computed
    get isHighlighted() {
        return this.selectedIndex !== undefined
    }

    @action.bound
    clearHighlight() {
        this.selectedIndex = undefined
    }

    getHilightedGroups() {
        const index = this.selectedIndex
        const section = this.selectedSection
        if (index === undefined) {
            return undefined
        } else {
            return [...this.data.matches[section].values()][index]
        }
    }

    constructor() {
        makeObservable(this);

        //action.bound solo funciona despues de makeObservable
        this.debouncedFilter = debounced(this.filter)

        // En cuanto estén los datos inicializados, inicializa el filtro
        autorun(async () => {
            if (allData.initialized) {
                await this._init()
            }
        })

        // Siempre que haya cambios actualizar filtro
        autorun(() => {
            // Reactividad
            const _ = [this.filterText, this.universityFilter, this.areaFilter]

            if (!allData.initialized) {
                return
            }

            runInAction(() => {
                this.selectedIndex = undefined
            })
            this.debouncedFilter()
        })
    }

    async _init(){
        if (!allData.initialized) {return}
    }

    @action.bound
    filter() {
        const [filteredGroups, filterMatches] =
            filterGroups(allData.groups, this.filterText, this.universityFilter, this.areaFilter)
        this.data = new FilteredData(filteredGroups, filterMatches)
        this.count++
    }
    debouncedFilter

    @action.bound
    toggleArea(ref) {
        if (this.areaFilter.has(ref)) {
            this.areaFilter.delete(ref)
        } else {
            this.areaFilter.set(ref, allData.areas.get(ref))
        }
        this.areaFilter = new Map(this.areaFilter.entries())
    }

    @action.bound
    toggleUniversity(ref) {
        if (this.universityFilter.has(ref)) {
            this.universityFilter.delete(ref)
        } else {
            this.universityFilter.set(ref, allData.unis.get(ref))
        }
        this.universityFilter = new Map(this.universityFilter.entries())
    }

    @action.bound
    unselectArea(ref) {
        this.areaFilter.delete(ref)
        this.areaFilter = new Map(this.areaFilter.entries())
    }

    @action.bound
    unselectUniversity(ref) {
        this.universityFilter.delete(ref)
        this.universityFilter = new Map(this.universityFilter.entries())
    }

    @boundMethod
    isAreaSelected(ref) {
        return this.areaFilter.has(ref)
    }

    @boundMethod
    isUniSelected(ref) {
        return this.universityFilter.has(ref)
    }
}

export const filterState = new FilterState()
