interface Props{
    className?: string    
}

export function ChartIcon({className}: Props) {
    return (
        <svg className={`spx-icon ${!className ? '' : ` ${className}`}`} 
            viewBox="0 -960 960 960" fill="currentColor" width="2em" height="2em"
        >
            <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z"/>
        </svg>
    )
}
