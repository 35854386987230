import {NO_AREA, UniGroup} from "app/utils/dataTypes";
import {indexMapEntry, IndexMapLevel1, IndexMapLevel2} from "sopix/collection/arrayIndexing";

import {FilterMatches} from "app/filter/filterDefs";
import {filterState} from "app/filter/filterState";


export class FilteredData {

    groups: Map<any, UniGroup>

    matches: FilterMatches

    groupsByUni: IndexMapLevel1<UniGroup>

    groupsByArea: IndexMapLevel1<UniGroup>

    groupsBySubarea: IndexMapLevel1<UniGroup>
    //Este tiene menos en su primer nivel que groupsByArea porque no incluye las áreas sin subáreas

    groupsByCell: IndexMapLevel2<UniGroup>

    researchLinesCount = 0
    cellCount = 0

    maxGroupsInACell = 0

    constructor(groups: Map<any, UniGroup>, matches: FilterMatches) {
        this.groups = groups
        this.matches = matches

        this.groupsByUni = new Map()
        this.groupsByArea = new Map()
        this.groupsBySubarea = new Map()

        const areaFilterRefs = []
        const areaFilterParentRefs = []
        const areaFilter = filterState.areaFilter
        for (const area of filterState.areaFilter.values()) {
            if (!areaFilterRefs.includes(area.ref)) {
                areaFilterRefs.push(area.ref)
            }
            if (area.parent && !areaFilterParentRefs.includes(area.parent.ref)) {
                areaFilterParentRefs.push(area.parent.ref)
            }
        }

        function areaAllowed(ref) {
            if (!areaFilter.size) {
                return true
            }

            return areaFilterRefs.includes(ref)
        }

        function parentAreaAllowed(ref) {
            if (!areaFilter.size) {
                return true
            }

            return areaFilterParentRefs.includes(ref)
        }

        for (const group of groups.values()) {
            indexMapEntry(this.groupsByUni, group.university.ref, group.ref, group)

            for (const area of group.areas.values()) {
                if (!areaAllowed(area.ref) && !parentAreaAllowed(area.ref)) {
                    continue
                }
                indexMapEntry(this.groupsByArea, area.ref, group.ref, group)
            }

            for (const subarea of group.subareas.values()) {
                if (!areaAllowed(subarea.ref) && !areaAllowed(subarea.parent.ref)) {
                    continue
                }
                indexMapEntry(this.groupsBySubarea, subarea.ref, group.ref, group)
            }

            this.researchLinesCount += group.researchLines.length
        }

        // Añadimos primero grupos que van directamente en el área, sin subárea
        this.groupsByCell = new Map()
        for (const group of this.groups.values()) {

            // Recopila refs de areas de subareas
            const areasOfSubareasRef = []
            for (const subarea of group.subareas.values()) {
                if (areasOfSubareasRef.indexOf(subarea.parent.ref) < 0) {
                    areasOfSubareasRef.push(subarea.parent.ref)
                }
            }

            // Añade areas sin subáreas
            for (const area of group.areas.values()) {
                if (areasOfSubareasRef.indexOf(area.ref) < 0) {
                    if (!areaAllowed(area.ref)) {
                        continue
                    }
                    indexMapEntry(this.groupsByCell, [area.ref, area.ref], group.ref, group)
                }
            }
        }

        // Luego añadimos las subáreas
        for (const group of this.groups.values()) {
            for (const subarea of group.subareas.values()) {
                if (!areaAllowed(subarea.ref) && !areaAllowed(subarea.parent.ref)) {
                    continue
                }

                indexMapEntry(this.groupsByCell, [subarea.parent.ref, subarea.ref], group.ref, group)
            }
        }

        // Por último añadimos los que no tienen área ni subárea
        for (const group of this.groups.values()) {
            if (group.areas.size || group.subareas.size || areaFilterRefs.length) {
                continue
            }

            indexMapEntry(this.groupsByCell, [NO_AREA.ref, NO_AREA.ref], group.ref, group)
        }

        // Contar
        let maxGroupsInACell = 0
        for (const groupsByCellsArea of this.groupsByCell.values()) {
            this.cellCount += 1 + groupsByCellsArea.size

            for (const groupsByLeafCell of groupsByCellsArea.values()) {
                if (groupsByLeafCell.size > maxGroupsInACell) {
                    maxGroupsInACell = groupsByLeafCell.size
                }
            }
        }
        this.maxGroupsInACell = maxGroupsInACell

    }

}
