import React from "react";

interface Props{
    paths?: {[key: string]: string}
}

export const ClipPathList = ({paths}: Props) => {
    return (!paths ? null :
        <svg width={0} height={0}>
            <defs>
                <>
                    {Array.from(Object.entries(paths)).map(([key, path]) => {
                        return (
                            <clipPath key={key} id={key} clipPathUnits="objectBoundingBox" >
                                <path d={path} />
                            </clipPath>
                        )
                    })}
                </>
            </defs>
        </svg>
    )
}