/** @jsxImportSource @emotion/react */
import './ChartView.scss'
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef} from "react";
import {useSizeObserver} from "sopix/hooks/useSizeObserver";
import {chartViewState} from "app/chartView/chartViewState";
import {Grid} from "app/chartView/grid/Grid";
import {getRenderCell} from "app/chartView/cell/Cell";
import {css} from "@emotion/react";
import {allData} from "app/filter/allData";
import {computed} from "mobx";
import {GroupIconDefinition} from "app/icons/GroupIcon";
import {ClipPathList} from "sopix/svg/ClipPathList";
import {NO_AREA} from "app/utils/dataTypes";
import {filterState} from "app/filter/filterState";
import {urlState} from "app/app/urlState";

interface Props {
}

export const ChartView = observer((props: Props) => {
    const ref = useRef<HTMLDivElement>()

    useEffect(() => {
        if (chartViewState) {
            chartViewState.bindElement(ref.current)

            if (chartViewState.invalidated) {
                chartViewState.update()
            }

            return chartViewState.unbindElement
        }
    }, [])

    useSizeObserver(ref, chartViewState.onElementResize)

    const anepCss = computed(() => {
        if (!allData.initialized || !chartViewState.gridData || !chartViewState.element || !chartViewState.gridSize) {
            return null
        }
        const maxWidth = chartViewState.gridSize.xCount * 256
        const maxHeight = chartViewState.gridSize?.yCount * 256

        return css(css`
          max-width: ${maxWidth}px;
          max-height: ${maxHeight}px;

          .grid {
            width: ${Math.min(maxWidth, chartViewState.element.clientWidth)}px;
            height: ${Math.min(maxHeight, chartViewState.element.clientHeight)}px;
          }

          .grid .cell {
            ${Array.from([...allData.areas.values(), NO_AREA]).map(
                    (a, i) => `&.${a.ref} {background: ${a.backgroundColor}; color: ${a.color}}`
            )}
            ${Object.entries(chartViewState.gridData.clipPaths).map(
                    ([key]) => `&.mask-${key} {clip-path: url(#${key});}`
            )}
          }

          .grid .cell .group {
            width: ${4 + 30 / filterState.data.maxGroupsInACell}%;
            height: ${4 + 30 / filterState.data.maxGroupsInACell}%;
          }

        `)
    }).get()

    const render = (inner?) =>
        <div className="chart-view-container">
            <section className="chart-view" ref={ref} css={anepCss}>
                {inner}
            </section>
        </div>

    const renderCell = computed(() => getRenderCell(chartViewState?.gridData)).get()

    const gridSize = chartViewState.gridSize
    const gridData = chartViewState.gridData

    if (!gridSize) {
        return render()

    } else {
        return render(<>
            <ClipPathList paths={gridData?.clipPaths}/>

            <GroupIconDefinition/>

            <Grid xCount={gridSize.xCount} yCount={gridSize.yCount}
                  render={renderCell}>
            </Grid>
        </>)
    }

})
