/** @jsxImportSource @emotion/react */
import './Grid.scss'
import {ReactElement, ReactNode, useMemo} from "react";
import {css} from "@emotion/react";
import {times} from "lodash-es";

interface Props{
    xCount: number
    yCount: number
    render: (x: number, y: number, cellNo: number) => ReactElement
    children?: ReactNode
}

export function Grid({xCount, yCount, render, children}: Props) {

    const gridCss = useMemo(() => {
        const colSizes = times(xCount).map(() => `${100/xCount}%`)
        const rowSizes = times(yCount).map(() => `${100/yCount}%`)

        return css(css`
          grid-template-columns: ${colSizes.join(' ')};
          grid-template-rows: ${rowSizes.join(' ')};
        `)
    }, [xCount, yCount])

    const cells = []
    let cellNo = 0
    for(let y= 0; y < yCount; y++) {
        const row = []
        for(let x= 0; x < xCount; x++) {
            cells.push(render(x, y, ++cellNo))
        }
        cells.push(row)
    }

    return <div className="grid" css={gridCss}>{cells}{children}</div>
}