import {createTheme, Theme, ThemeOptions} from "@mui/material";

// Typescript augmentation
declare module '@mui/material/styles' {
    interface CustomTheme extends Theme {
        myoptions: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface CustomThemeOptions extends ThemeOptions {
        themeName?: string
        myoptions?: {
            danger?: string;
        };
    }
    export function createTheme(options?: CustomThemeOptions): CustomTheme;
}


export const appTheme = createTheme({
    themeName: 'Tema de a4u research app',
    palette: {
        primary: {
            main: '#ff2d2d',
        }
    },
    myoptions: {
      danger: 'Cuidadín'
    }
})