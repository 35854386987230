// https://colorbrewer2.org/#type=qualitative&scheme=Set3&n=10
export const class10Set3Colors = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', 
    '#d9d9d9', '#bc80bd']

// https://colorbrewer2.org/#type=qualitative&scheme=Accent&n=4
export const class4AccentColors = ['#7fc97f','#beaed4','#fdc086','#ffff99'];


// /data/david/proyectos/polytherm-sop-front/node_modules/chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau.js
export const tableau20Colors = ['#4E79A7', '#A0CBE8', '#F28E2B', '#FFBE7D', '#59A14F', '#8CD17D', '#B6992D', '#F1CE63', '#499894', '#86BCB6', '#E15759', '#FF9D9A', '#79706E', '#BAB0AC', '#D37295', '#FABFD2', '#B07AA1', '#D4A6C8', '#9D7660', '#D7B5A6'];
