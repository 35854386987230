interface Props{
    className?: string    
}

export function ExternalIcon({className}: Props) {
    return (
        <svg className={`spx-icon ${!className ? '' : ` ${className}`}`} 
            viewBox="0 0 10.3 10.3" fill="currentColor" width="1em" height="1.1em"
        >
            <polygon className="st0"
                     points="8.1,0.2 1.1,0.2 1.1,1.9 6.9,1.9 0.1,8.7 1.4,9.9 8.1,3.2 8.1,9 9.9,9 9.9,1.9 9.9,0.2 "/>
        </svg>
    )
}
