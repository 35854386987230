import {action, autorun, makeObservable, observable} from "mobx";
import {GridData} from "app/chartView/grid/gridData";
import {filterState} from "app/filter/filterState";
import {debounced} from "app/utils/debouncing";
import {GridSize} from "app/chartView/grid/gridSize";
import {boundMethod} from "autobind-decorator";
import {urlState} from "app/app/urlState";

class ChartViewState {

    @observable
    element?: HTMLElement

    elementWidth?: number
    elementHeight?: number

    @observable.ref
    gridSize: GridSize

    @observable.ref
    gridData?: GridData

    invalidated = false

    constructor() {
        makeObservable(this)

        //action.bound solo funciona despues de makeObservable
        this.debouncedUpdate = debounced(this.update)

        // En cuanto cambien los datos del filtro, actualiza la vista
        autorun(() => {
            if (filterState.data) {
                this.update()
            }
        })
    }

    @action.bound
    bindElement(element: HTMLDivElement) {
        if (this.element) {
            throw new Error('element already registered')
        }

        this.element = element
    }

    @action.bound
    unbindElement() {
        if (!this.element) {
            throw new Error('no element to unregister')
        }
        this.element = undefined
    }

    @action.bound
    update(){
        this.invalidated = true

        if (!filterState.data || this.element === undefined) {return}

        const cellCount = filterState.data.cellCount

        // Update grid
        if (!this.element.offsetWidth || !this.element.offsetHeight) {
            this.gridSize = undefined
        } else {
            const gridState = new GridSize(this.element.offsetWidth, this.element.offsetHeight, cellCount)
            if (!this.gridSize || !gridState.hasSameSize(this.gridSize)) {
                this.gridSize = gridState
            }
        }

        // Update AnepGrid
        if (this.gridSize && filterState.data) {
            this.gridData = new GridData(this.gridSize, filterState.data)
            this.invalidated = false
        }
    }
    debouncedUpdate

    @boundMethod
    onElementResize(){

        // No repetir
        if(this.elementWidth === this.element.offsetWidth && this.elementHeight === this.element.offsetHeight) {
            return
        }
        this.elementWidth = this.element.offsetWidth
        this.elementHeight = this.element.offsetHeight

        // Actualizar
        this.debouncedUpdate()
    }
}

export const chartViewState = new ChartViewState()
