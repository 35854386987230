import {forEach} from 'lodash-es'
import {boundMethod} from 'autobind-decorator'

export class ObserverHubById {
    observers = new Map()

    subscribe = (id, observer) => this.observers.set(id, observer)

    unsubscribe = id => this.observers.delete(id)

    notify = (...args) => {
        for(let observer of this.observers.values()){
            observer(...args)
        }
    }
}

let observerHubOnError

export function setObserverHubErrorMiddleware(onError){
    observerHubOnError = onError
}

export class ObserverHub<T extends [...any]> {
    observers = new Set<(...T) => void>()
    
    _disabled = 0
    
    disable = () => this._disabled++

    enable = () => this._disabled--
    
    subscribe = (observer: (...args: T) => void) => this.observers.add(observer)

    unsubscribe = (observer: (...args: T) => void) => this.observers.delete(observer)

    @boundMethod
    notify(...args: T){
        if (this._disabled > 0) return
        for(let observer of this.observers){
            if (!observerHubOnError) {
                observer(...args)
            } else {
                try{
                    observer(...args)
                    
                    //Captura el error y continua
                }catch (e) {
                    observerHubOnError(e)
                }
            }
        }
    }
}

export class ChainHubById {
    agents: {[key: string]: (...args) => any} = {}

    subscribe = (id, agent) => this.agents[id] = agent

    unsubscribe = id => delete this.agents[id]

    get = (...args) => {
        let result = undefined
        forEach(this.agents, agent => {
            const response = agent(...args)
            if (response !== undefined) {
                result = response
                return false
            }            
        })
        return result
    }
}