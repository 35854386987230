interface Props{
    className?: string    
}

export function DocumentIcon({className}: Props) {
    return (
        <svg className={`spx-icon ${!className ? '' : ` ${className}`}`} 
            viewBox="0 -960 960 960" fill="currentColor" width="2em" height="2em"
        >
            <path xmlns="http://www.w3.org/2000/svg" d="M280-280h280v-80H280v80Zm0-160h400v-80H280v80Zm0-160h400v-80H280v80Zm-80 480q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
        </svg>
    )
}
