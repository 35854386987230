/** @jsxImportSource @emotion/react */
import React from "react";
import {TitleCellState} from "app/chartView/cell/cellState";
import clsx from "clsx";
import {observer} from "mobx-react-lite";
import {Tooltip} from "@mui/material";
import {NO_AREA} from "app/utils/dataTypes";
import {useCellRegister} from "app/chartView/cell/cellUtils";
import {getHighlightInfo} from "app/filter/filterUtils";
import {Pop} from "app/components/Pop";
import {CellPop} from "app/chartView/CellPop";
import {computed} from "mobx";
import {urlState} from "app/app/urlState";
import {emulateBack, pushUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";

interface Props {
    cell: TitleCellState
    maskRef: string
}

export const TitleCell = observer(({cell, maskRef}: Props) => {
    const [highlighted, dimmed, highlightedGroups] = getHighlightInfo([...cell.groups.keys()])

    const ref = useCellRegister(cell)

    const leafBorder = cell.leafAreaSide

    const areaFocus = cell.focused || cell.leafFocused


    const clicked = event => {
        event.stopPropagation()

        pushUrl(updateUrlQuery({
            pop: 'title-cell',
            cell: cell.areaRef,
            // autoclose: 'y',
        }))
    }

    const renderCell = () => (
        <div ref={ref} onClick={clicked} className={clsx(
            'cell',
            'content-cell',
            'title-cell',
            dimmed && 'dimmed',
            cell.areaRef,
            `mask-${maskRef}`,
            leafBorder && `no-border-${leafBorder}`,
            areaFocus && 'area-focus',
        )}>
            <div className="background"
                // css={css(css`
                //     background: url(/img/anep/${cell.area.ref}.webp) center center no-repeat;
                //     background-size: cover;
                // `)}
            />
            <div className="content">
                <div className="count">{cell.groups.size}</div>

                {cell.area === NO_AREA ? null :
                    <Tooltip title={cell.area.name} placement="top" disableInteractive>
                        <p className="ref">{cell.areaRef}</p>
                    </Tooltip>
                }
                <p className="title">{cell.area.name}</p>
            </div>
        </div>
    )

    const closed = () => {

        if (urlState.pop !== 'title-cell') {
            return
        }

        emulateBack(updateUrlQuery({
            pop: '',
            cell: '',
            autoclose: '',
        }))
    }

    const isOpen = computed(() => urlState.pop === 'title-cell' && urlState.cell === cell.areaRef).get()

    return (
        <>
            {renderCell()}
            <Pop
                id={`cell-${cell.areaRef}`}
                anchorEl={ref.current}
                open={isOpen}
                onClose={closed}
            >
                <CellPop cell={cell} onClose={closed}/>
            </Pop>
        </>
    )

})