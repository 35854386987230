import {anepColors20, anepContrastColors20} from "../../styles/colors";

export interface UniGroupRaw {
    ref: string
    name: string
    university_ref: string
    department_ref: string
    ips: string
    web: string
    research_lines: string
}

export type AreaMap = Map<string, AnepArea>

export interface UniGroup {
    ref: string
    name: string
    university: Uni
    department: UniDepartment
    ips: string[]
    webs: string[]
    researchLines: string[]
    areas: AreaMap
    subareas: AreaMap
}

export interface UniDepartment {
    ref: string
    name: string
}

export interface AnepArea {
    ref: string
    name: string
    parent?: AnepArea
    subareas: AreaMap
    backgroundColor: string
    color: string
}

export interface AnepAreaRaw {
    ref: string
    name: string
    parent_ref: string
}

export interface Uni{
    ref: string
    name: string
}

export interface GroupAreaRel {
    group_ref: string,
    area_ref: string,
}


export const NO_AREA: AnepArea = {
    'ref': '___',
    'name': 'Unclassified',
    'subareas': new Map(),
    'backgroundColor': anepColors20[19],
    'color': anepContrastColors20[19],
}


export function getAreaPath(area: AnepArea) {
    if (!area) {
        return '?'
    } else if (area.parent) {
        return `${area.parent.ref}/${area.ref}`
    } else {
        return `${area.ref}`
    }
}