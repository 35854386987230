import {allData} from "app/filter/allData";
import {action, computed, makeObservable, observable} from "mobx";
import {routers} from "sopix/router/router";
import {getUniqueId} from "sopix/unique-id";
import {getUrlQueryParams} from "sopix/router/routerUtils";

class UrlState {

    routerLeastenerId = getUniqueId()

    @observable
    view: 'chart' | 'text' | undefined

    @observable
    group: string

    @observable
    cell: string

    @observable
    pop: 'group' | 'cell' | 'title-cell' | undefined

    @observable
    filter: string

    @observable
    unis: string

    @observable
    areas: string

    @observable
    dropdown: 'area' | 'uni' | undefined

    @action
    onUrlChanged() {
        const params = getUrlQueryParams()

        const update = (key) => {
            const param = params[key]
            if (param !== this[key]) {
                this[key] = param
            }
        }

        update('view')
        update('group')
        update('cell')
        update('pop')
        update('filter')
        update('unis')
        update('areas')
        update('dropdown')
    }

    constructor() {
        makeObservable(this)

        this.onUrlChanged()
        routers.subscribe(this.routerLeastenerId, this.onUrlChanged.bind(this))
    }
}

export const urlState = new UrlState()
