import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'styles/global.scss'
import vars from 'styles/vars.module.scss'

export const cssVars = vars

const test_color_assignment = vars.color_uab
