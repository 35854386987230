import './FilterMatches.scss'
import {filterState} from "app/filter/filterState";
import {observer} from "mobx-react-lite";
import {Button, Chip, Divider, List, ListItem, ListItemButton, ListItemText, Tooltip} from "@mui/material";
import React, {MouseEventHandler, ReactNode, useState} from "react";
import {action} from "mobx";
import {FilterMatchSectionName} from "app/filter/filterDefs";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import clsx from "clsx";
import {useGoTopOnFilter} from "app/filter/filterHooks";
import {appState} from "app/app/appState";
import {pushUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface FilterMatchProps{
    section: FilterMatchSectionName
    index: number
    children: ReactNode
}


function isSelected(section, index){
    return filterState.selectedSection === section && index === filterState.selectedIndex
}

const FilterMatch = observer(({section, index, children}: FilterMatchProps) => {

    const isSel = isSelected(section, index)

    const itemClick = action<MouseEventHandler>(e => {
        filterState.selectedIndex = isSel ? undefined : index
        filterState.selectedSection = section

        if (appState.screenSize === 'xs') {
            pushUrl(updateUrlQuery({
                view: 'text',
            }))
        }

    })

    const renderContent = () =>
        <ListItem disablePadding>
            <ListItemButton dense onClick={itemClick} selected={isSelected(section, index)}>
                <ListItemText>
                    {children}
                </ListItemText>
            </ListItemButton>
        </ListItem>

    return !isSel ? renderContent() :
        <Tooltip title="Click again to unhighlight" placement="right">
            {renderContent()}
        </Tooltip>

})


function renderMatches(section: FilterMatchSectionName): [number, ReactNode[]] {
    const filter = filterState.data
    const matches = filter?.matches

    if (!matches) {
        return [0, []]
    }

    const entries = []
    let n = 0
    const texts = [...matches[section].keys()]
    for (const text of texts) {
        entries.push(<FilterMatch key={n++} section={section} index={n}>{text}</FilterMatch>)
        if (n >= 50) {
            entries.push(
                <ListItem key="_more" disablePadding>
                    <ListItemButton dense disabled>
                        <ListItemText>
                            {`... (+ ${texts.length - n} omitted)`}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            )
            break
        }
    }

    return [texts.length, entries]
}

interface MatchSectionProps {
    title: string
    count: number
    children: ReactNode[]
}

const MatchesSection = observer(({title, count, children}: MatchSectionProps) => {

    const [expanded, setExpanded] = useState<Boolean>(true);

    const handleChange = event => {
        setExpanded(!expanded);
    };

    return !children.length ? null : (
        <>
            <h5 className={clsx('section', expanded && 'expanded', !expanded && 'folded')} onClick={handleChange}>
                <Chip className="count" color="secondary" label={count} size="small"/>
                <span>{title}</span>
                {expanded ? <ArrowDropUp/> : <ArrowDropDown/>}
            </h5>
            {!expanded ? null :
                <List>
                    {children}
                </List>
            }
        </>
    )
})

export const FilterMatches = observer(() => {

    const filter = filterState.data
    const matches = filter?.matches

    const [groupNameCount, groupNames] = renderMatches('groupName')
    const [researchLineCount, researchLines] = renderMatches('researchLine')
    const [ipsCount, ips] = renderMatches('ips')

    const ref = useGoTopOnFilter()

    const viewResultsClick = event => {
        if (appState.screenSize !== 'xs') {
            return
        }

        filterState.clearHighlight()

        pushUrl(updateUrlQuery({
            view: 'text',
        }))
    }

    return (
        <div className="filter-matches">
            {!matches || (!matches.groupName.size && !matches.researchLine.size && !matches.ips.size) ? null :
                <div ref={ref} className="container">
                    <div className="small-screen">
                        <div className="buttons">
                            <Button endIcon={<NavigateNextIcon/>} onClick={viewResultsClick}>
                                {`View ${filterState.data.groups.size} groups`}
                            </Button>
                        </div>
                        <Divider/>
                    </div>
                    <MatchesSection title="group name" count={groupNameCount}>
                        {groupNames}
                    </MatchesSection>
                    <MatchesSection title="research line" count={researchLineCount}>
                        {researchLines}
                    </MatchesSection>
                    <MatchesSection title="principal investigator" count={ipsCount}>
                        {ips}
                    </MatchesSection>
                </div>
            }
        </div>
    )
})