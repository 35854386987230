import {observer} from "mobx-react-lite";
import {Badge, Chip} from "@mui/material";
import {filterState} from "app/filter/filterState";
import React from "react";

export const Highlighted = observer(() => {
    const highlightedGroups = !filterState.isHighlighted ? undefined : filterState.getHilightedGroups()
    return (
        <Badge className="highlighted" badgeContent={highlightedGroups.size} max={999}
               color="secondary">
            <Chip label="Highlighted" onDelete={filterState.clearHighlight}/>
        </Badge>
    )
})