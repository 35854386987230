import "./Cell.scss"
import React from "react";
import {GridData} from "app/chartView/grid/gridData";
import {AreaCellState, TitleCellState, NullCellState, SubareaCellState} from "app/chartView/cell/cellState";
import {TitleCell} from "app/chartView/cell/TitleCell";
import {LeafCell} from "app/chartView/cell/LeafCell";
import {NullCell} from "app/chartView/cell/NullCell";


export const getRenderCell = (anepGrid2: GridData) => (x, y, cellNo) => {

    const cells = anepGrid2?.cells

    if (!cells) {
        return null
    }
    const cell = cells[y][x]
    const key = `${cell.x}-${cell.y}`

    const boundaries = anepGrid2.grid.getCellBoundaries(x, y)
    const maskRef = boundaries.map(b => b ? '1' : '0').join('')

    return (
        cell instanceof TitleCellState ?
            <TitleCell key={key} cell={cell} maskRef={maskRef}/>

            : cell instanceof SubareaCellState || cell instanceof AreaCellState ?
                <LeafCell key={key} cell={cell} maskRef={maskRef} cellNo={cellNo}/>

        : cell instanceof NullCellState ?
            <NullCell key={key} cell={cell} />

        : null
    )
}
