import {MutableRefObject, useEffect} from "react"

export function useSizeObserver<T extends HTMLElement = HTMLElement>(ref: MutableRefObject<T>
                                                                     , onWidthChange: (element: T) => void){
    useEffect(() => {
        const element = ref.current
        if (!element) {
            return
        }
        
        function sizeChanged() {
            onWidthChange(element)
        }

        let sizeObserver
        try {
            sizeObserver = new ResizeObserver(sizeChanged)
            sizeObserver.observe(element)
        }catch(e){
            console.log(e)
        }

        return () => {
            sizeObserver.unobserve(element)
        }

    }, [ref, onWidthChange])

}