import './FilterSummary.scss'
import {Badge, Chip} from "@mui/material";
import {filterState} from "app/filter/filterState";
import {observer} from "mobx-react-lite";

export const FilterSummary = observer(() => {

    return !filterState.data ? null : <div className="filter-summary">
        <Badge className="badge groups" badgeContent={filterState.data.groups.size} max={999}
               color="secondary">
            <Chip label="Groups"/>
        </Badge>
        <Badge className="badge areas" badgeContent={filterState.data.groupsByArea.size} color="secondary">
            <Chip label="Areas"/>
        </Badge>
        <Badge className="badge subareas" badgeContent={filterState.data.groupsBySubarea.size}
               color="secondary">
            <Chip label="Subareas"/>
        </Badge>
    </div>
})