import './Group.scss'
import {UniGroup} from "app/utils/dataTypes";
import {getLeafAreas} from "app/utils/areaUtils";
import {ExternalIcon} from "app/icons/ExternalIcon";
import {Chip} from "@mui/material";
import clsx from "clsx";
import {cssVars} from "styles/styles";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import {forwardRef} from "react";
import {WebList} from "app/components/WebList";

interface Props{
    group: UniGroup
    repeated?: boolean
}

export const Group = forwardRef<HTMLDivElement, Props>(({group, repeated}: Props, ref) => {

    const uniRef = group.university.ref.toLowerCase()

    const areaItems = []
    const leafAreas = getLeafAreas(group.areas, group.subareas)
    for (const area of leafAreas.values()){
        areaItems.push(
            <Chip key={area.ref} className="area" size="medium" sx={{
                'background': area.backgroundColor,
                'color': area.color,
            }} label={
                <span>
                    <span className="area-ref">{area.ref}</span>
                    <span className="area-name">{area.name}</span>
                </span>
            } />
        )
    }
    
    const lineaItems = []
    let lineaNo = 0
    for (const linea of group.researchLines) {
        lineaNo++
        lineaItems.push(
            <Chip key={lineaNo} className="line" size="medium" label={linea}/>
        )
    }

    const ipsItems = []
    let ipsNo = 0
    for (const ips of group.ips) {
        ipsNo++
        ipsItems.push(
            <Chip key={ipsNo} className="ips" size="medium" sx={{
                'background': cssVars[`color_${uniRef}`],
            }}
                  label={ips}/>
        )
    }

    const clicked = event => {
        event.stopPropagation()
    }

    return(
        <div ref={ref} className="group">
            <div className={clsx('pop-header', `background-${uniRef}`)}>
                <div className="title">
                    <SupervisedUserCircleIcon className="group-icon"/>
                    <h4>{group.name}</h4>
                </div>
                {!repeated ? null :
                    <div className="repeated">
                        <Chip label="repeated above in another area"/>
                    </div>
                }
            </div>
            <div className="group-website">
                <WebList webs={group.webs}/>
            </div>

            {!ipsItems.length ? null :
                <div className="ips">
                    <h5>Principal investigator</h5>
                    {ipsItems}
                </div>
            }

            {!areaItems.length ? null :
                <div className="anep">
                    <h5>AEI areas</h5>
                    {areaItems}
                </div>
            }

            {!lineaItems.length ? null :
                <div className="lines">
                    <h5>Research lines</h5>
                    {lineaItems}
                </div>
            }

            <div className={clsx('uni', group.university.ref)}>
                <img src={`/img/logo-${group.university.ref.toLowerCase()}.png`} />
                {!group.department ? null :
                    <div className="department">
                        <h5>Department</h5>
                        <div className="department-name">{group.department.name}</div>
                    </div>
                }
            </div>

        </div>
    )
})