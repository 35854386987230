import {defaultTo, isArray} from 'lodash-es'
import {compile, match} from 'path-to-regexp'
import {ReactElement} from "react";

type strOrArray = string | string[]

export function route(routes: Map<strOrArray, (params?) => ReactElement>, location?): ReactElement | false{
    const loc = defaultTo(location, window.location)
    const locationPath = loc.pathname

    for (let [paths, get] of routes.entries()){
        const pathList = isArray(paths) ? paths : [paths]
        for (let path of pathList){
            const matches = match(path, {
                decode: decodeURIComponent
            })
            const info = matches(locationPath)
            if (info) {
                return get(info.params)
            }
        }
    }
    return false
}


export function getRouteParams(routes){
    const routeList = isArray(routes) ? routes : [routes]
    for (let route of routeList){
        const info = match(route)(window.location.pathname)
        if (info) {
            return info.params
        }        
    }
    return undefined
}

export function parseUrlParam(urlId){
    const intId = urlId === undefined ? undefined : parseInt(urlId)
    return Number.isNaN(intId) ? null : intId
}

export function getUrlFromRoute(route, params){
    const makeUrl = compile(route,{ encode: encodeURIComponent }) 
    return makeUrl(params)  
}


export function getUrlQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
}


export function updateUrlQuery(params: Record<string, string>) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of Object.entries(params)) {
        if (value) {
            urlSearchParams.set(key, value)
        } else {
            if (urlSearchParams.has(key)) {
                urlSearchParams.delete(key)
            }
        }
    }
    const url = new URL(window.location.href)
    url.search = `?${urlSearchParams.toString()}`
    return url.toString()
}