export interface Vector2d {
    x?: number,
    y?: number,
}

export class Vector2 implements Vector2d{
    constructor(public x?: number, public y?: number) {
    }

    get isUndefined() {
        return this.x === undefined || this.y === undefined
    }

    equal(v: Vector2d){
        return this.x === v.x && this.y === v.y
    }

    offset(v: Vector2d){
        return new Vector2((v.x || 0) - (this.x || 0), (v.y || 0) - (this.y || 0))
    }
}
