import {AnepArea, AnepAreaRaw, GroupAreaRel, Uni, UniDepartment, UniGroup, UniGroupRaw} from "app/utils/dataTypes";
import {fetchCsv} from "sopix/csv/fetchCsv";
import {makeObservable, observable, runInAction} from "mobx";
import {arrayToMap, indexMap, IndexMapLevel1} from "sopix/collection/arrayIndexing";
import {anepColors20, anepContrastColors20} from "../../styles/colors";

class AllData {

    @observable
    initialized: boolean = false

    areas: Map<any, AnepArea>
    unis: Map<any, Uni>
    departments: Map<any, UniDepartment>
    groups: Map<any, UniGroup>

    constructor() {
        makeObservable(this)
    }

    private createAreas(areasRaw: Map<any, AnepAreaRaw>) {
        // Crear áreas
        this.areas = new Map()
        const areas = this.areas

        // Paso 1: excepto subareas (se presume que primero vienen las áreas y luego las subáreas)
        let areaNo = 0
        for (const [ref, areaRaw] of areasRaw.entries()) {
            let background
            let color
            if (!areaRaw.parent_ref) {
                const iColor = areaNo++ % 20
                background = anepColors20[iColor]
                color = anepContrastColors20[iColor]
            }
            areas.set(ref, {
                ref: ref,
                name: areaRaw.name,
                parent: areaRaw.parent_ref ? areas.get(areaRaw.parent_ref) : undefined,
                subareas: new Map(),
                backgroundColor: background,
                color: color,
            })
        }

        // Paso 2: asignar subareas
        const areasRawByParentRef = indexMap(areasRaw, 'parent_ref') as IndexMapLevel1<AnepAreaRaw>
        for (const [ref, area] of areas.entries()) {
            const rawSubareas = areasRawByParentRef.get(ref)
            if (rawSubareas) {
                const childRefs = Array.from(rawSubareas.keys())
                const childs = new Map()
                for (const childRef of childRefs) {
                    const area = areas.get(childRef)
                    childs.set(childRef, area)
                }
                area.subareas = childs
            }
        }

        for (const area of areas.values()) {
            if (area.parent) {
                area.backgroundColor = area.parent.backgroundColor
                area.color = area.parent.color
            }
        }
    }

    private createGroups(groupsRaw: Map<any, UniGroupRaw>, groupAreaRels: Map<any, GroupAreaRel>) {
        const groupRefByAreaRef = indexMap(groupAreaRels, 'area_ref')

        this.groups = new Map()
        const groups = this.groups

        const areaRelsByGroup = indexMap<GroupAreaRel>(groupAreaRels, 'group_ref') as IndexMapLevel1<GroupAreaRel>

        for (const [ref, groupRaw] of groupsRaw.entries()) {

            const areaRels = areaRelsByGroup.get(ref)
            const mainAreas: Map<any, AnepArea> = new Map()
            const subareas: Map<any, AnepArea> = new Map()

            if (areaRels) {

                //Main areas
                for (const rel of areaRels.values()) {
                    let area = this.areas.get(rel.area_ref)
                    if (area.parent) {
                        area = area.parent
                    }

                    // No repetir
                    if (mainAreas.has(area.ref)) {
                        continue
                    }

                    mainAreas.set(area.ref, area)
                }

                //Subareas
                for (const rel of areaRels.values()) {
                    let area = this.areas.get(rel.area_ref)
                    if (!area.parent) {
                        continue
                    }

                    // No repetir
                    if (subareas.has(area.ref)) {
                        continue
                    }

                    subareas.set(area.ref, area)
                }

            }


            const rawLines = !groupRaw.research_lines ? [] : groupRaw.research_lines
                .replace(/^\s+|\s+$/g, '')
                .split('|')

            const lines = []
            for (const rawLine of rawLines) {
                if (!rawLine) {
                    continue
                }

                if (rawLine.endsWith('.')) {
                    lines.push(rawLine)
                } else {
                    lines.push(`${rawLine}.`)
                }
            }

            const ips = !groupRaw.ips ? [] : groupRaw.ips.replace(/^\s+|\s+$/g, '').split('|')

            const webText = groupRaw.web
                .replaceAll(' , ', '|')
                .replaceAll(', ', '|')
                .replaceAll(',', '|')
                .replaceAll(' ', '|')

            const webs = []
            for (const web of webText.split('|')) {
                if (!web) {
                    continue
                }
                const webFixed = !web.startsWith('http') ? `https://${web}` : web.replaceAll('http:', 'https:')
                webs.push(webFixed)
            }

            groups.set(ref, {
                ref: ref,
                name: groupRaw.name,
                university: this.unis.get(groupRaw.university_ref),
                department: this.departments.get(groupRaw.department_ref),
                ips: ips,
                webs: webs,
                researchLines: lines,
                areas: mainAreas,
                subareas: subareas,
            })
        }
    }

    async init(){
        if (this.initialized) {return}

        let areasRaw: Map<any, AnepAreaRaw>
        let groupsRaw: Map<any, UniGroupRaw>
        let groupAreaRels: Map<any, GroupAreaRel>

        [areasRaw, this.unis, this.departments, groupsRaw, groupAreaRels] = await Promise.all([
            arrayToMap(await fetchCsv('/data/area.csv'), 'ref'),
            arrayToMap(await fetchCsv('/data/uni.csv'), 'ref'),
            arrayToMap(await fetchCsv('/data/department.csv'), 'ref'),
            arrayToMap(await fetchCsv('/data/group.csv'), 'ref'),
            arrayToMap(await fetchCsv('/data/group_area.csv')),
        ])

        this.createAreas(areasRaw)
        this.createGroups(groupsRaw, groupAreaRels)

        runInAction(() => {
            this.initialized = true
        })
    }

}

export const allData = new AllData()
