/** @jsxImportSource @emotion/react */
import './CellPop.scss'
import {css} from "@emotion/react";
import {Chip, IconButton} from "@mui/material";
import {AreaCellState, LeafCellState, SubareaCellState, TitleCellState} from "app/chartView/cell/cellState";
import {cssVars} from "styles/styles";
import {pushUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CloseIcon from "@mui/icons-material/Close";


interface GroupBlockProps {
    cell: LeafCellState
}

function GroupBlock({cell}: GroupBlockProps) {
    const ref = cell.ref
    const groupClicked = (event, group) => {
        event.stopPropagation()

        pushUrl(updateUrlQuery({
            pop: 'group',
            cell: ref,
            group: group.ref,
            autoclose: ''
        }))
    }
    const groupItems = []
    for (const group of cell.groups.values()) {

        // Clickable false evita que se mui cambie el estilo en hover
        groupItems.push(
            <Chip key={group.ref} className="group" size="medium" sx={{
                'background': cssVars[`color_${group.university.ref.toLowerCase()}`],
            }} label={group.name} onClick={event => groupClicked(event, group)} clickable={false}/>
        )
    }
    return !groupItems.length ? null : <>{groupItems}</>
}

interface SubareaContentProps {
    cell: SubareaCellState
}

function SubareaContent({cell}: SubareaContentProps) {

    const clicked = event => {
        event.stopPropagation()

        pushUrl(updateUrlQuery({
            pop: 'title-cell',
            cell: cell.areaRef,
            autoclose: '',
        }))
    }

    return <>
        <div className="parent-area">
            <h3>Parent area</h3>
            <Chip size="medium" label={cell.titleCell.name} sx={{
                'background': cell.area.backgroundColor,
                'color': cell.area.color,
            }} onClick={clicked}/>
        </div>
        {!cell.groups.size ? null :
            <div className="groups">
                <h3><SupervisedUserCircleIcon/>Groups</h3>
                <GroupBlock cell={cell}/>
            </div>
        }
    </>

}


interface AreaContentProps {
    cell: TitleCellState
}

function AreaContent({cell}: AreaContentProps) {

    const blocks = []

    for (const leafCell of cell.leafCells.values()) {
        if (!leafCell.groups.size) {
            continue
        }
        blocks.push(
            <div key={leafCell.ref} className="groups">
                <h3>
                    <SupervisedUserCircleIcon/>{leafCell instanceof AreaCellState ? 'Groups' : `Groups (${leafCell.ref}: ${leafCell.name})`}
                </h3>
                <GroupBlock cell={leafCell}/>
            </div>
        )
    }

    return !blocks.length ? null : <>{blocks}</>
}


interface Props {
    cell: SubareaCellState | TitleCellState
    onClose?: () => void
}

export const CellPop = ({cell, onClose}: Props) => {

    const ref = cell instanceof LeafCellState ? cell.ref : cell.areaRef

    const closeClicked = event => {
        event.stopPropagation()

        onClose && onClose()
    }

    return(
        <div className="cell-pop" onClick={event => event.stopPropagation()}>
            <div className="pop-header" css={css(css`
                background: ${cell.area.backgroundColor};
                color: ${cell.area.color};
            `)}>
                <div className="anep">{ref}</div>
                <h2>{cell.name}</h2>
                <div className="tipo">{cell instanceof SubareaCellState ? 'Subarea' : 'Area'}</div>
                <IconButton className="close-button" aria-label="close" size="small" onClick={closeClicked}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </div>

            {cell instanceof TitleCellState ?
                <AreaContent cell={cell}/> :
                <SubareaContent cell={cell}/>
            }
        </div>
    )
}