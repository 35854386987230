import clsx from "clsx";

interface Props {
    className?: string
}

export function GroupIconDefinition() {
    return (
        <svg style={{'display': 'None'}} aria-hidden="true" id="svgdefs" version="2" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <symbol id="group-icon-definition" fill="currentColor" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/>
                </symbol>
            </defs>
        </svg>
    )
}


export function GroupIcon({className}: Props) {
    return (
        <svg className={clsx('group-icon', className)} fill="currentColor" viewBox="0 0 512 512">
            <use href="#group-icon-definition"/>
        </svg>
    )
}
