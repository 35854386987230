import {useEffect} from "react";
import {urlState} from "app/app/urlState";
import {FrameWithFilter} from "app/frame/FrameWithFilter";
import {ChartView} from "app/chartView/ChartView";
import {TextView} from "app/textView/TextView";
import {observer} from "mobx-react-lite";
import {Home} from "app/home/Home";
import {appState} from "app/app/appState";

export const AppRouter = observer(() => {

    useEffect(() => {
        (async () => {
            await appState.init()
        })()
    })

    if (urlState.view === 'text') {
        return (
            <FrameWithFilter>
                <TextView/>
            </FrameWithFilter>
        )

    } else if (urlState.view === 'chart') {
        return (
            <FrameWithFilter>
                <ChartView/>
            </FrameWithFilter>
        )
    } else {
        return <Home/>
    }
})