import './Footer.scss'
import {ExternalIcon} from "app/icons/ExternalIcon";
import {filterState} from "app/filter/filterState";
import {Chip} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GroupIcon} from "app/icons/GroupIcon";
import {urlState} from "app/app/urlState";

export const Footer = observer(() => {
    return (
        <footer className="footer">
            <span className="copyright">© Copyright - Alliance4universities</span>
            {urlState.view !== 'chart' ? null :
                <div className="legend">
                    <h5>Legend</h5>
                    <GroupIcon className="icon"/>
                    <span>: A research group in a subject area. You can hover and click them.</span>
                </div>
            }
            <a className="link" href="https://alliance4universities.eu/en/">
                <span>A4U website</span>
                <ExternalIcon/>
            </a>
        </footer>
    )
})