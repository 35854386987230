import {Popover} from "@mui/material";
import React, {ReactNode, TransitionEventHandler, useState} from "react";

interface Props{
    id: string
    open: boolean
    anchorEl: HTMLElement
    children: ReactNode
    onClose?: () => void
    onOpen?: TransitionEventHandler<HTMLElement>
}

export const Pop = ({id, open, anchorEl, onClose, onOpen, children}: Props) => {

    const closed = () => {
        onClose && onClose()
    }

    const transitionEnd = event => {
        onOpen && onOpen(event)
    }

    if (!anchorEl) {
        return null
    }

    return (
        <Popover
            id={`popover-${id}`}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            onClose={closed}
            onTransitionEnd={transitionEnd}
        >
            {children}
        </Popover>
    )
}