export function *meander(xCount: number, yCount: number): Generator<{x: number, y: number}, any, unknown>{
    for(let y= 0; y < yCount; y++) {
        for(let x= 0; x < xCount; x++) {
            yield {
                x: y % 2 ? xCount - x - 1 : x,
                y
            }
        }
    }

}