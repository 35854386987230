import {Badge, Box, Chip, Tab, Tabs, Tooltip} from "@mui/material";
import React, {useState} from "react";
import './Header.scss'
import {FilterSummary} from "app/filter/FilterSummary";
import {pushUrl, replaceUrl} from "sopix/router/router";
import {ChartIcon} from "app/icons/ChartIcon";
import {DocumentIcon} from "app/icons/DocumentIcon";
import {urlState} from "app/app/urlState";
import {updateUrlQuery} from "sopix/router/routerUtils";
import {WEB_TITLE} from "app/app/defs";
import {filterState} from "app/filter/filterState";
import {observer} from "mobx-react-lite";
import {appState} from "app/app/appState";
import {Highlighted} from "app/filter/Highlighted";
import {chartViewState} from "app/chartView/chartViewState";

const CHART_VIEW = 0
const TEXT_VIEW = 1

export const Header = observer(() => {

    const handleChange = (event, newValue) => {
        if (newValue === CHART_VIEW) {
            pushUrl(updateUrlQuery({
                view: 'chart',
            }))
        } else {
            pushUrl(updateUrlQuery({
                view: 'text',
            }))
        }
    }

    const logoClicked = event => {
        event.stopPropagation()
        pushUrl(updateUrlQuery({
            view: '',
        }))
    }

    return (
        <div className="header">
            <div className="title" onClick={logoClicked}>
                <img className="logo" src="/img/logo-a4u.png" alt="A4U logo"/>
                <h1>{WEB_TITLE}</h1>
            </div>
            {!filterState.isHighlighted ? null : <Highlighted/>}
            <FilterSummary/>
            <nav>
                <Box>
                    <Tabs className="tabs" value={urlState.view === 'text' ? TEXT_VIEW : CHART_VIEW}
                          onChange={handleChange}
                          aria-label="View selection" textColor="inherit" indicatorColor="secondary">
                        <Tooltip title="Chart view" placement="top">
                            <Tab id="tab-chart-view" label="Chart" icon={<ChartIcon/>}/>
                        </Tooltip>
                        <Tooltip title="Text view" placement="top">
                            <Tab id="tab-text-view" label="Text" icon={<DocumentIcon/>}/>
                        </Tooltip>
                    </Tabs>
                </Box>
            </nav>
        </div>

    )
})
