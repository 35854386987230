import "./Home.scss"
import {observer} from "mobx-react-lite";
import {Button, Paper} from "@mui/material";
import React from "react";
import {WEB_TITLE} from "app/app/defs";
import {pushUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";
import {Footer} from "app/frame/Footer";

export const Home = observer(() => {
    const buttonClicked = event => {
        event.stopPropagation()
        pushUrl(updateUrlQuery({
            view: 'chart',
        }))
    }

    return (
        <div className="home" onClick={buttonClicked}>
            <Paper className="frame">
                <div className="header">
                    <img className="logo" src="/img/logo-a4u.png" alt="A4U logo"/>
                    <h1>{WEB_TITLE}</h1>
                </div>
                <div className="content">
                    Explore all our research groups classified by subject area.
                </div>
                <div className="buttons" >
                    <Button variant="contained" size="large" onClick={buttonClicked}>Explore</Button>
                </div>
            </Paper>
            <Footer/>
        </div>
    )
})