import {AnepArea, Uni, UniGroup} from "app/utils/dataTypes";
import {FilterMatches} from "app/filter/filterDefs";
import {indexMapEntry} from "sopix/collection/arrayIndexing";
import {intersection} from "lodash-es";
import {filterState} from "app/filter/filterState";
import {AreaCellState, SubareaCellState, TitleCellState} from "app/chartView/cell/cellState";
import {castToBool} from "sopix/form/form-types";
import {allData} from "app/filter/allData";
import {urlState} from "app/app/urlState";


export function createFilterMatches() {
    return {
        groupName: new Map(),
        researchLine: new Map(),
        ips: new Map(),
    }
}

export function filterGroups(
    groups: Map<any, UniGroup>,
    filterText: string, uniFilter: Map<any, Uni>,
    areaFilter: Map<any, AnepArea>
): [Map<any, UniGroup>, FilterMatches] {

    const filterMatches = createFilterMatches()

    const filteredGroups = new Map()
    const words = filterText
        .toLowerCase()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .split(' ')

    function check(text) {
        if (!text) {
            return false
        }
        for (const word of words) {
            if (!text
                .toLowerCase()
                .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                .includes(word)) {
                return false
            }
        }
        return true
    }

    const areaFilterRefs = [...areaFilter.keys()]

    for (const group of groups.values()) {
        let include = false

        if (uniFilter.size && !uniFilter.has(group.university.ref)) {
            continue
        }

        if (areaFilter.size) {
            const groupRefs = [...group.areas.keys(), ...group.subareas.keys()]
            const filteredRefs = intersection(groupRefs, areaFilterRefs)
            if (!filteredRefs.length) {
                continue
            }
        }

        if (check(group.name)) {
            include = true
            indexMapEntry(filterMatches.groupName, group.name, group.ref, group)
        }

        for (const line of group.researchLines) {
            if (check(line)) {
                include = true
                indexMapEntry(filterMatches.researchLine, line, group.ref, group)
            }
        }

        for (const ips of group.ips) {
            if (check(ips)) {
                include = true
                indexMapEntry(filterMatches.ips, ips, group.ref, group)
            }
        }

        if (include) {
            filteredGroups.set(group.ref, group)
        }
    }

    return [filteredGroups, filterMatches]
}


export function getHighlightInfo(groupRefs: string[]): [boolean, boolean, Map<string, UniGroup>] {
    const highlightedGroups = filterState.getHilightedGroups()
    const highlighActive = highlightedGroups !== undefined
    const highlighted = highlighActive &&
        !!intersection([...highlightedGroups.keys()], groupRefs).length
    const dimmed = highlighActive && !highlighted

    return [highlighted, dimmed, highlightedGroups]
}


export function getMapFromUrl<T>(urlKey, allDataKey) {
    const _reacting_ = allData.initialized
    const map = new Map<any, T>()
    const urlProp = urlState[urlKey]
    if (allData.unis) {
        for (const ref of (urlProp || '').split('.')) {
            const prop = allData[allDataKey].get(ref)
            if (!prop) {
                continue
            }
            map.set(ref, prop)
        }
    }
    return map
}