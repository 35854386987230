import './GroupPop.scss'
import {UniGroup} from "app/utils/dataTypes";
import {getLeafAreas} from "app/utils/areaUtils";
import {Chip, IconButton} from "@mui/material";
import clsx from "clsx";
import {cssVars} from "styles/styles";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CloseIcon from '@mui/icons-material/Close';
import {WebList} from "app/components/WebList";
import {pushUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";

interface Props{
    group: UniGroup
    onClose?: () => void
}

export const GroupPop = ({group, onClose}: Props) => {

    const uniRef = group.university.ref.toLowerCase()

    const areaClicked = (event, area) => {
        event.stopPropagation()

        pushUrl(updateUrlQuery({
            pop: area.parent ? 'cell' : 'title-cell',
            cell: area.ref,
            group: '',
            autoclose: '',
        }))
    }

    const areaItems = []
    const leafAreas = getLeafAreas(group.areas, group.subareas)
    for (const area of leafAreas.values()){
        areaItems.push(
            <Chip key={area.ref} className="area" size="medium" sx={{
                'background': area.backgroundColor,
                'color': area.color,
            }} onClick={event => areaClicked(event, area)} clickable={false} label={
                <span>
                    <span className="area-ref">{area.ref}</span>
                    <span className="area-name">{area.name}</span>
                </span>
            } />
        )
    }
    
    const lineaItems = []
    let lineaNo = 0
    for (const linea of group.researchLines) {
        lineaNo++
        lineaItems.push(
            <Chip key={lineaNo} className="line" size="medium" label={linea}/>
        )
    }

    const ipsItems = []
    let ipsNo = 0
    for (const ips of group.ips) {
        ipsNo++
        ipsItems.push(
            <Chip key={ipsNo} className="ips" size="medium" sx={{
                'background': cssVars[`color_${uniRef}`],
            }}
                  label={ips}/>
        )
    }

    const closeClicked = event => {
        event.stopPropagation()

        onClose && onClose()
    }

    return(
        <div className="group-pop" onClick={event => event.stopPropagation()}>
            <div className={clsx('pop-header', `background-${uniRef}`)}>
                <SupervisedUserCircleIcon className="group-icon"/>
                <h2>{group.name}</h2>
                <div className="tipo">Group</div>
                <IconButton className="close-button" aria-label="close" size="small" onClick={closeClicked}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </div>
            <div className="group-website">
                <WebList webs={group.webs}/>
            </div>

            {!ipsItems.length ? null :
                <div className="ips">
                    <h3>Principal investigator</h3>
                    {ipsItems}
                </div>
            }

            {!areaItems.length ? null :
                <div className="anep">
                    <h3>AEI areas</h3>
                    {areaItems}
                </div>
            }

            {!lineaItems.length ? null :
                <div className="lines">
                    <h3>Research lines</h3>
                    {lineaItems}
                </div>
            }

            <div className={clsx('uni', group.university.ref)}>
                <img src={`/img/logo-${group.university.ref.toLowerCase()}.png`} />
                {!group.department ? null :
                    <div className="department">
                        <h3>Department</h3>
                        <div className="department-name">{group.department.name}</div>
                    </div>
                }
            </div>

        </div>
    )
}