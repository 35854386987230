import React, {useEffect, useState} from "react";
import {AreaCellState, SubareaCellState} from "app/chartView/cell/cellState";
import clsx from "clsx";
import {observer} from "mobx-react-lite";
import {emulateBack, pushUrl} from "sopix/router/router";
import {urlState} from "app/app/urlState";
import {updateUrlQuery} from "sopix/router/routerUtils";
import {Pop} from "app/components/Pop";
import {Bolita} from "app/chartView/cell/Bolita";
import {CellPop} from "app/chartView/CellPop";
import {computed} from "mobx";
import {useCellRegister} from "app/chartView/cell/cellUtils";
import {getHighlightInfo} from "app/filter/filterUtils";


interface Props {
    cell: SubareaCellState | AreaCellState
    maskRef: string
    cellNo: number
}

export const LeafCell = observer(({cell, maskRef, cellNo}: Props) => {
    const [highlighted, dimmed, highlightedGroups] = getHighlightInfo([...cell.groups.keys()])

    const ref = useCellRegister(cell)

    const clicked = event => {
        event.stopPropagation()

        pushUrl(updateUrlQuery({
            pop: 'cell',
            cell: cell.ref,
            // autoclose: 'y'
        }))
    }

    const closed = () => {

        if (urlState.pop !== 'cell') {
            return
        }

        emulateBack(updateUrlQuery({
            pop: '',
            cell: '',
            autoclose: ''
        }))
    }

    const [renderGroups, setRenderGroups] = useState(false)

    useEffect(() => {
        if (renderGroups) {
            return
        }

        const timeoutId = setTimeout(() => {
            setRenderGroups(true)
        }, cellNo * 20)

        return () => clearTimeout(timeoutId)
    }, [cellNo, renderGroups]);


    const isArea = !(cell instanceof SubareaCellState)

    const titleBorder = isArea ? cell.titleSide : undefined

    const areaFocus = cell.titleCell.focused || cell.titleCell.leafFocused

    const renderCell = () => (
        <div ref={ref} onClick={clicked}
             className={clsx(
                 'cell',
                 'content-cell',
                 'leaf-cell',
                 isArea && 'leaf-area',
                 dimmed && 'dimmed',
                 highlighted && 'highlighted',
                 'subarea',
                 cell.areaRef,
                 `mask-${maskRef}`,
                 titleBorder && `no-border-${titleBorder}`,
                 areaFocus && 'area-focus',
             )}>

            {isArea ? null :
                <div className="cell-header">
                    <span className="ref">{cell.ref}</span>
                    <span className="title">{cell.name}</span>
                </div>
            }

            {!renderGroups ? null :
                <div className="groups">
                    {(() => {
                        const result = []
                        for (let group of cell.groups.values()) {
                            result.push(
                                <Bolita key={group.ref} cell={cell} group={group}
                                        dimmed={highlighted && !highlightedGroups.has(group.ref)}/>
                            )
                        }
                        return result
                    })()}
                </div>
            }
        </div>
    )

    const isOpen = computed(() => urlState.pop === 'cell' && urlState.cell === cell.ref).get()

    return (
        <>
            {renderCell()}
            <Pop
                id={`cell-${cell.ref}`}
                anchorEl={ref.current}
                open={isOpen}
                onClose={closed}
            >
                <CellPop cell={cell instanceof SubareaCellState ? cell : cell.titleCell} onClose={closed}/>
            </Pop>
        </>
    )
})