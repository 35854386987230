import {useEffect, useRef, useState} from "react";
import {filterState} from "app/filter/filterState";

export function useGoTopOnFilter(){
    const ref = useRef<HTMLDivElement>()
    const [filterCount, setFilterCount] = useState(0)
    useEffect(() => {
        if (filterState.count > filterCount && ref.current) {
            ref.current.scrollTo({top: 0})
            setFilterCount(filterState.count)
        }
    }, [filterState.count]);
    return ref
}
