import React from 'react';
import './App.css';

// Configuración de scss
import 'styles/styles'
import {Experimental_CssVarsProvider as CssVarsProvider, ThemeProvider} from "@mui/material";

// Tema a medida
import {appTheme} from "./styles/theme";

import {AppRouter} from "app/app/AppRouter";

//Check mobx config is ok (https://mobx.js.org/installation.html)
if (!new class {
  x: any
}().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');

function App() {

  return (
      <ThemeProvider theme={appTheme}>
          <CssVarsProvider>
            <AppRouter />
          </CssVarsProvider>
      </ThemeProvider>
  );
}

export default App;
