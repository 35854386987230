import {CellBaseState} from "app/chartView/cell/cellState";
import {useEffect, useRef} from "react";

export function useCellRegister(cell: CellBaseState) {
    const ref = useRef()

    useEffect(() => {
        const el = ref.current
        cell.registerElement(el)
        return () => cell.unregisterElement(el)
    }, [cell]);

    return ref
}