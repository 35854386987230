import {AreaCellState, SubareaCellState} from "app/chartView/cell/cellState";
import {UniGroup} from "app/utils/dataTypes";
import {observer} from "mobx-react-lite";
import React, {useRef} from "react";
import {emulateBack, pushUrl} from "sopix/router/router";
import {updateUrlQuery} from "sopix/router/routerUtils";
import {Tooltip} from "@mui/material";
import clsx from "clsx";
import {GroupIcon} from "app/icons/GroupIcon";
import {Pop} from "app/components/Pop";
import {urlState} from "app/app/urlState";
import {GroupPop} from "app/chartView/GroupPop";

interface Props {
    cell: SubareaCellState | AreaCellState
    group: UniGroup
    dimmed: boolean
}

export const Bolita = observer(({cell, group, dimmed}: Props) => {
    const ref = useRef()

    const clicked = event => {
        event.stopPropagation()

        pushUrl(updateUrlQuery({
            pop: 'group',
            cell: cell.ref,
            group: group.ref,
        }))
    }

    const closed = () => {

        if (urlState.pop !== 'group') {
            return
        }

        emulateBack(updateUrlQuery({
            pop: '',
            cell: '',
            group: '',
        }))
    }

    return (
        <>
            <Tooltip title={group.name} disableInteractive >
                <div ref={ref} className={clsx('group', dimmed && 'dimmed')} onClick={clicked}>
                    <GroupIcon/>
                </div>
            </Tooltip>
            <Pop
                id={`group-${cell.ref}-${group.ref}`}
                anchorEl={ref.current}
                open={urlState.pop === 'group' && urlState.cell === cell.ref && urlState.group === group.ref}
                onClose={closed}
            >
                <GroupPop group={group} onClose={closed}/>
            </Pop>
        </>
    )
})